import React from 'react';
import { graphql } from 'gatsby';

import * as Core from 'components/core';
import Layout from 'components/departments/DepartmentLayout';

interface Props {
  data: {
    halBrands: Core.ImageParagraphWrapImageProps;
    felipeCampante: Core.ImageParagraphWrapImageProps;
    craigOsborne: Core.ImageParagraphWrapImageProps;
    christopherSands: Core.ImageParagraphWrapImageProps;
    jessicaFanzo: Core.ImageParagraphWrapImageProps;
    adriaLawrence: Core.ImageParagraphWrapImageProps;
    jeremeyShiffman: Core.ImageParagraphWrapImageProps;
    anneApplebaum: Core.ImageParagraphWrapImageProps;
    samAsher: Core.ImageParagraphWrapImageProps;
    danielMarston: Core.ImageParagraphWrapImageProps;
    paulaThornhill: Core.ImageParagraphWrapImageProps;
    eugeneFinkel: Core.ImageParagraphWrapImageProps;
    walterAndersen: Core.ImageParagraphWrapImageProps;
    lingChen: Core.ImageParagraphWrapImageProps;
    sarahJordaan: Core.ImageParagraphWrapImageProps;
    sarahParkinson: Core.ImageParagraphWrapImageProps;
    nargesBajoghli: Core.ImageParagraphWrapImageProps;
    china: Core.ImageAttributes;
    americaFirst: Core.ImageAttributes;
    venezuela: Core.ImageAttributes;
    johnMcLaughlin: Core.ImageAttributes;
  };
}

const DepartmentContent: React.FC<Props> = (props) => {
  const {
    halBrands,
    felipeCampante,
    craigOsborne,
    christopherSands,
    jessicaFanzo,
    adriaLawrence,
    jeremeyShiffman,
    anneApplebaum,
    samAsher,
    danielMarston,
    paulaThornhill,
    eugeneFinkel,
    walterAndersen,
    lingChen,
    sarahJordaan,
    sarahParkinson,
    nargesBajoghli,
    china,
    americaFirst,
    venezuela,
    johnMcLaughlin
  } = props.data;

  return (
    <Layout departmentSlug="dispatch" title="Dispatch">
      <Core.ContentWrapper>
        <Core.ContentSection>
          <Core.DepartmentLabel>Dispatch</Core.DepartmentLabel>
          <Core.Flag>SAIS News</Core.Flag>
        </Core.ContentSection>
        <Core.ContentSection id="new-senior-leadership">
          <Core.PrimaryHeading>New Senior Leadership</Core.PrimaryHeading>
          <Core.SecondaryHeading>Hal Brands</Core.SecondaryHeading>
          <Core.ImageParagraphWrap image={halBrands}>
            Johns Hopkins University Provost Sunil Kumar has designated Brands
            as a Provost’s Fellow for the coming year, with the mission of
            enhancing SAIS’ collaboration with the Krieger School of Arts and
            Sciences. This will have multiple dimensions, including increasing
            the number of SAIS faculty members teaching on the Homewood campus,
            bringing Krieger School undergraduates to SAIS, exploring
            co-curricular activities, and deepening links with the program in
            international studies at the Krieger School, which will be chaired
            by Johns Hopkins SAIS Professor Adria Lawrence.
          </Core.ImageParagraphWrap>
          <Core.SecondaryHeading>Filipe Campante</Core.SecondaryHeading>
          <Core.ImageParagraphWrap image={felipeCampante}>
            Campante has accepted the position as vice dean for education and
            academic affairs, helping to lead the transformation of Johns
            Hopkins SAIS’ academic programs. Campante joined the Johns Hopkins
            University in 2018 as a Bloomberg Distinguished Associate Professor
            of International Economics, holding appointments at both Johns
            Hopkins SAIS and the Krieger School of Arts and Sciences. His
            cross-disciplinary scholarship on political economy, development
            economics, and urban and regional issues has significantly shaped
            our understanding of urban economies, particularly the impact of
            spatial distributions of people and economic activity. His work has
            appeared in leading academic journals such as American Economic
            Review and The Quarterly Journal of Economics, among others; in The
            New York Times, Science, The Washington Post, The Economist, the Los
            Angeles Times, Foreign Affairs, and Politico; and on NPR.
          </Core.ImageParagraphWrap>
          <Core.SecondaryHeading>Craig Osborne</Core.SecondaryHeading>
          <Core.ImageParagraphWrap image={craigOsborne}>
            Osborne joins the Johns Hopkins SAIS senior leadership team as chief
            of staff. He recently retired from the U.S. Army after 30 years of
            distinguished service that included humanitarian relief efforts,
            peace enforcement operations, and several combat deployments to
            Afghanistan and Iraq. During his career, he commanded several units
            — including both a battalion and a brigade — and he served on many
            staffs, such as the 82nd Airborne Division, U.S. European Command,
            the Joint Staff, and NATO headquarters. Of note, Osborne served as a
            special assistant to the chairman of the Joint Chiefs of Staff and,
            in his most recent assignment, served as chief of staff to the
            provost and then executive officer to the university president of
            the National Defense University in Washington, D.C.
          </Core.ImageParagraphWrap>
          <Core.SecondaryHeading>Christopher Sands</Core.SecondaryHeading>
          <Core.ImageParagraphWrap image={christopherSands}>
            Sands has been appointed acting director of the Latin American
            Studies Program. He is currently a senior research professor and
            director of the Center for Canadian Studies. He represents Johns
            Hopkins SAIS on the faculty advisory council to the Johns Hopkins
            University Research Administration, the universitywide office of
            sponsored research.
          </Core.ImageParagraphWrap>
        </Core.ContentSection>

        <Core.HorizontalRule />

        <Core.ContentSection id="cross-university-faculty-appointments">
          <Core.PrimaryHeading>
            Cross-University Faculty Appointments
          </Core.PrimaryHeading>
          <p>
            The following faculty members at Johns Hopkins SAIS hold joint
            appointments with other university divisions:
          </p>
          <Core.ImageParagraphWrap image={jessicaFanzo}>
            <Core.BoldText>Jessica Fanzo</Core.BoldText> is the Bloomberg
            Distinguished Associate Professor of Ethics & Global Food and
            Agriculture at Johns Hopkins SAIS, the Berman Institute of
            Bioethics, and the Department of International Health in the Johns
            Hopkins Bloomberg School of Public Health.
          </Core.ImageParagraphWrap>
          <Core.ImageParagraphWrap image={adriaLawrence}>
            <Core.BoldText>Adria Lawrence</Core.BoldText> is the Aronson
            Associate Professor of International Studies and Political Science
            at the Johns Hopkins University, a joint appointment with Johns
            Hopkins SAIS and the Department of Political Science at the Krieger
            School of Arts and Sciences. She is a scholar of Middle Eastern and
            North African politics. She studies colonialism, nationalism,
            conflict, and collective action.
          </Core.ImageParagraphWrap>
          <Core.ImageParagraphWrap image={jeremeyShiffman}>
            <Core.BoldText>Jeremey Shiffman</Core.BoldText> is the Bloomberg
            Distinguished Professor of Global Health Policy at the Johns Hopkins
            University, with joint appointments in the Bloomberg School of
            Public Health (Department of International Health) and Johns Hopkins
            SAIS. A political scientist by training, his research focuses on the
            politics of health policy processes in low-income countries and in
            global governance. His research has been funded by the Bill &
            Melinda Gates, MacArthur, Rockefeller, and Open Society foundations,
            among other organizations. His work has appeared in multiple
            journals, including The Lancet and the American Journal of Public
            Health.
          </Core.ImageParagraphWrap>
        </Core.ContentSection>

        <Core.HorizontalRule />

        <Core.ContentSection id="new-faculty">
          <Core.PrimaryHeading>New Faces</Core.PrimaryHeading>
          <p>
            Johns Hopkins SAIS welcomed the following new faculty members to
            campus this fall:
          </p>
          <Core.ImageParagraphWrap image={anneApplebaum}>
            <Core.BoldText>Anne Applebaum</Core.BoldText> is senior fellow of
            international affairs and an Agora Fellow in Residence. Applebaum is
            a Pulitzer Prize–winning author and noted historian whose books
            include Red Famine: Stalin’s War on Ukraine and Gulag. She is a
            foreign policy columnist for The Washington Post and regularly
            writes for Foreign Affairs. A Yale University graduate, she obtained
            her master’s degree from the London School of Economics in
            international relations.
          </Core.ImageParagraphWrap>
          <Core.ImageParagraphWrap image={samAsher}>
            <Core.BoldText>Sam Asher</Core.BoldText> is assistant professor of
            International Economics. Prior to joining Johns Hopkins SAIS, he
            served as an economist in the Development Research Group at the
            World Bank. Asher’s research focuses on economic activity, growth,
            and poverty in developing countries. He received his PhD in
            economics from Harvard University and served as a postdoctoral
            research fellow in the Economics Department of Nuffield College at
            the University of Oxford.
          </Core.ImageParagraphWrap>
          <Core.ImageParagraphWrap image={danielMarston}>
            <Core.BoldText>Daniel Marston</Core.BoldText> is professor of the
            practice and director of the Strategic Thinkers Program, a new
            partnership between the school and the U.S. Department of Defense.
            Marston’s research focuses on how armies learn and adapt to new
            environments. He has authored or co-authored several books,
            including Phoenix from the Ashes, which won the Templer Medal Book
            Prize in 2003. Marston received a doctoral degree in imperial and
            commonwealth history from the University of Oxford.
          </Core.ImageParagraphWrap>
          <Core.ImageParagraphWrap image={paulaThornhill}>
            <Core.BoldText>Paula Thornhill</Core.BoldText> is associate director
            of Strategic Studies and associate professor of the practice. After
            retiring from the U.S. Air Force at the rank of brigadier general,
            Thornhill has researched military education, training, and strategy.
            She is the author of Demystifying the American Military:
            Institutions, Evolution, and Challenges Since 1789. Thornhill
            obtained master’s degrees from the National War College and Stanford
            University, and a doctoral degree in history from the University of
            Oxford.
          </Core.ImageParagraphWrap>
        </Core.ContentSection>

        <Core.HorizontalRule />

        <Core.ContentSection id="new-doctoral-program-launched">
          <Core.PrimaryHeading>
            New Doctoral Program Launched
          </Core.PrimaryHeading>
          <p>
            Johns Hopkins SAIS has expanded its degree offerings with a new
            Doctor of International Affairs (DIA). Meant for experienced
            professionals to advance career pathways as international relations
            practitioners rather than as academics, the DIA will be delivered in
            a hybrid format and meet an important need in the international
            affairs space.
          </p>
          <p>
            “This innovative program will be the first accelerated practitioner
            doctorate in international affairs offered at a leading academic
            institution,” says Dean Eliot Cohen, noting that the DIA can be
            completed in just two to three years—much more quickly than the
            common seven-year time frame of a traditional PhD. “It will
            introduce a new kind of scholar-practitioner prepared to take on the
            most complex global issues of our time,” he adds.
          </p>
          <p>
            The new degree program is designed to prepare experienced
            professionals with the research capabilities they need to become
            high-level policy planning experts, national security analysts,
            think tank scholars, and other senior-level experts across sectors
            and industries.
          </p>
          <p>
            &ldquo;The DIA will offer a flexible curriculum to allow students to
            focus on the issues of greatest importance to their careers,” Cohen
            adds. “Students will receive advanced research training that can be
            applied in institutions that shape policy, and they can practice in
            a wide range of organizations, from businesses to multinational
            organizations.&rdquo;
          </p>
          <p>
            The program was launched in February 2019 and its future is bright.
          </p>
        </Core.ContentSection>

        <Core.HorizontalRule />

        <Core.ContentSection id="new-doctoral-program-launched">
          <Core.PrimaryHeading>
            Launching the Master of Arts in European Public Policy
          </Core.PrimaryHeading>
          <p>
            Based at SAIS Europe, the Master of Arts in European Public Policy
            (also called the MEPP), prepares students for careers involving the
            EU. Through rigorous academic instruction and hands-on practical
            training, students gain an insider’s understanding of the EU and the
            skills needed for a successful career in EU institutions, diplomacy,
            government affairs, risk analysis, journalism, lobbying, consulting,
            and more.
          </p>
          <p>
            “This new, one-year MA gives students seeking careers in the EU
            policy community a clear advantage,” says SAIS Europe Director
            Michael G. Plummer.
          </p>
          <p>
            Applications are now open for the inaugural summer 2020 cohort. For
            more details, visit{' '}
            <a href="http://sais.jhu.edu/mepp">sais.jhu.edu/mepp</a>.
          </p>
          <p>
            <i>
              This degree program is pending review and endorsement by MHEC.
            </i>
          </p>
        </Core.ContentSection>

        <Core.HorizontalRule />

        <Core.ContentSection id="faculty-honored">
          <Core.PrimaryHeading>Faculty Honored</Core.PrimaryHeading>
          <Core.ImageParagraphWrap image={eugeneFinkel}>
            <Core.BoldText>Eugene Finkel</Core.BoldText>, associate professor of
            Conflict Management, European and Eurasian Studies, and
            International Relations, received the Bronislaw Malinowski Social
            Sciences Award from The Polish Institute of Arts and Sciences in
            America for his recently published book, Ordinary Jews: Choice and
            Survival during the Holocaust.
          </Core.ImageParagraphWrap>
          <Core.ImageParagraphWrap image={walterAndersen}>
            <Core.BoldText>Walter Andersen</Core.BoldText>, senior adjunct
            professor of South Asia Studies, won Tata Literature Live’s 2018
            Book of the Year for his recently published book, Messengers of
            Hindu Nationalism: How the RSS Reshaped India.
          </Core.ImageParagraphWrap>

          <Core.HorizontalRule />

          <p>
            Three SAIS faculty members were honored with 2019 Johns Hopkins
            Catalyst Awards. These awards support the promising research and
            creative endeavors of early-career faculty — with the goal of
            launching them on a path to a sustainable and rewarding academic
            career. The SAIS awardees are:
          </p>

          <Core.ImageParagraphWrap image={lingChen}>
            <Core.BoldText>Ling Chen</Core.BoldText>, assistant professor of
            Political Economy. Her research interests lie in political economy
            and government-business relations in China and East Asia, especially
            the political origins of economic policies.
          </Core.ImageParagraphWrap>
          <Core.ImageParagraphWrap image={sarahJordaan}>
            <Core.BoldText>Sarah Jordaan</Core.BoldText>, assistant professor of
            Energy, Resources, and Environment, and of Canadian Studies. Her
            research is aimed at uncovering the environmental and economic
            trade-offs related to energy decisions, particularly those
            trade-offs related to the life cycle of energy technologies.
          </Core.ImageParagraphWrap>
          <Core.ImageParagraphWrap image={sarahParkinson}>
            <Core.BoldText>Sarah Parkinson</Core.BoldText>, Aronson Assistant
            Professor of Political Science and International Studies. Her
            research examines organizational behavior and social change during
            and following war. Focusing predominantly on the Middle East and
            North Africa, she uses social network analysis and ethnographic
            methods to study the ways that actors such as militant
            organizations, political parties, and humanitarian groups cope with
            crisis, disruption, and fragmentation.
          </Core.ImageParagraphWrap>

          <Core.HorizontalRule />

          <Core.ImageParagraphWrap image={nargesBajoghli}>
            <Core.BoldText>Narges Bajoghli</Core.BoldText>, assistant professor
            of Middle East Studies, was honored with a Johns Hopkins Discovery
            Award. In 2019, 32 Discovery Awards were given to interdisciplinary
            teams across 12 units of Johns Hopkins. Award winners are poised to
            arrive at important discoveries or creative works. Bajoghli will
            work on a project titled “Invitation to the Masses: The Russian and
            Iranian Revolutions and their Arts of Persuasion,” together with
            Niloofar Haeri (KSAS) and Anne Eakin Moss (KSAS).
          </Core.ImageParagraphWrap>
        </Core.ContentSection>

        <Core.HorizontalRule />

        <Core.Flag>SAIS in the News</Core.Flag>

        <Core.ContentSection
          marginalia={
            <Core.FullWidthImage
              image={china}
              alt="China's Expanding Influence"
              contentSectionPlacement="marginalia"
            />
          }
          id="chinas-expanding-influence"
        >
          <Core.PrimaryHeading>China’s Expanding Influence</Core.PrimaryHeading>
          <p>
            China’s bid for more influence through regional and global
            partnerships has led to increased scrutiny. In response, SAIS
            experts have explored the potential impact of China investing in
            infrastructure and development projects across the globe.
          </p>

          <p>
            <Core.LeadIn>John Lipsky</Core.LeadIn>, the Peter G. Peterson
            Distinguished Scholar at the Henry A. Kissinger Center for Global
            Affairs, told attendees at a lecture on Chinese trade covered by
            C-SPAN that with China’s Belt and Road Initiative, “there are issues
            of debt transparency, the planning process, and whether procurement
            is going to be seen as fair and open internationally.”{' '}
            <a href="http://jhu.informz.net/z/cjUucD9taT04NTQwMTE0JnA9MSZ1PTExMjQ5NjMyNTAmbGk9NjU5NDY1MDg/index.html">
              Watch here
            </a>
            .
          </p>

          <p>
            <Core.LeadIn>Deborah Bräutigam</Core.LeadIn>, the Bernard L.
            Schwartz Professor of Political Economy and director of the China
            Africa Research Initiative, wrote in <i>The American Interest</i>{' '}
            that Chinese investment in African countries’ infrastructure
            projects is “quite attractive to many low-income countries and we
            [the U.S.] do not have the tools to offer something better.”{' '}
            <a href="http://jhu.informz.net/z/cjUucD9taT04NTQwMTE0JnA9MSZ1PTExMjQ5NjMyNTAmbGk9NjU5NDY1MTM/index.html">
              Read more
            </a>
            .
          </p>
          <Core.Share id="chinas-expanding-influence" />
        </Core.ContentSection>

        <Core.HorizontalRule />

        <Core.ContentSection
          marginalia={
            <Core.FullWidthImage
              image={americaFirst}
              alt="America First"
              contentSectionPlacement="marginalia"
            />
          }
          id="america-first"
        >
          <Core.PrimaryHeading>‘America First’ Policies</Core.PrimaryHeading>
          <p>
            The Trump administration’s “America First” polices are shifting the
            landscape on issues long considered foundational to U.S. influence
            and leadership in the world. SAIS experts have analyzed these
            changing positions and what they mean for U.S.-global relations.
          </p>

          <p>
            Senior Research Professor of International Economics{' '}
            <Core.BoldText>Anne O. Krueger</Core.BoldText> wrote in
            <i>Project Syndicate</i> that “the current U.S. approach to
            [international] trade will result in deteriorating U.S. economic
            performance.”{' '}
            <a href="http://jhu.informz.net/z/cjUucD9taT04NDU0NTI3JnA9MSZ1PTExMjQ5NjMyNTAmbGk9NjQ2NzE1ODY/index.html">
              Read more
            </a>
            .
          </p>

          <p>
            Adjunct Lecturer <Core.BoldText>Benjamin Gedan</Core.BoldText>{' '}
            appeared on C-SPAN to argue that threatened cuts to funding for
            Central American nations would be counterproductive. “Governance is
            inadequate in these countries,” he said. “If the objective of the
            United States is to reduce immigration, the only way we will succeed
            is if those countries have an improved quality of life.”{' '}
            <a href="http://jhu.informz.net/z/cjUucD9taT04NDU0NTI3JnA9MSZ1PTExMjQ5NjMyNTAmbGk9NjQ2NzE1ODc/index.html">
              Watch for more
            </a>
            .
          </p>

          <p>
            Speyer Family Foundation Distinguished Scholar{' '}
            <Core.BoldText>Sarah Sewall</Core.BoldText> told{' '}
            <i>PRI’s The World</i> that the U.S. has not engaged the Chinese
            publicly on Tibetan human rights issues because “we don’t even have
            the moral leadership of pointing out the importance of this issue.”{' '}
            <a href="http://jhu.informz.net/z/cjUucD9taT04NDU0NTI3JnA9MSZ1PTExMjQ5NjMyNTAmbGk9NjQ2NzE1ODg/index.html">
              Listen for more
            </a>
            .
          </p>
          <Core.Share id="america-first" />
        </Core.ContentSection>

        <Core.HorizontalRule />

        <Core.ContentSection
          marginalia={
            <Core.FullWidthImage
              image={venezuela}
              alt="Leadership Crisis in Venezuela"
              contentSectionPlacement="marginalia"
            />
          }
          id="leadership-crisis-in-venezuela"
        >
          <Core.PrimaryHeading>
            Leadership Crisis in Venezuela
          </Core.PrimaryHeading>

          <p>
            Since January, there have been dueling claims from Nicolas Maduro
            and Juan Guaido for the Venezuelan presidency. SAIS experts have
            delved into how the country’s leadership crisis is impacting ongoing
            economic and political issues.
          </p>

          <p>
            <Core.BoldText>Anne O. Krueger</Core.BoldText>, senior research
            professor of International Economics, wrote in{' '}
            <i>Project Syndicate</i> that Venezuela needs to “restore a stable
            macroeconomic environment and business climate while simultaneously
            improving the lot of Venezuelan citizens, so that they will continue
            to support political reforms.”{' '}
            <a href="http://jhu.informz.net/z/cjUucD9taT04MzgwNjY0JnA9MSZ1PTEwNzE4NDI1NTImbGk9NjM3Mjk4MDk/index.html">
              Read more
            </a>
            .
          </p>

          <p>
            <Core.BoldText>Benjamin N. Gedan</Core.BoldText>, adjunct lecturer
            of Latin American Studies, said on Voice of America’s
            <i>Encounter</i> that economic sanctions issued by the U.S. are
            risky “because you’re guaranteed to negatively affect humanitarian
            conditions that are already dire, but you are not guaranteed to have
            the result you seek in dislodging Nicolas Maduro.”
          </p>

          <p>
            <Core.BoldText>Monica de Bolle</Core.BoldText>, the Riordan Roett
            Chair of Latin American Studies and director of the Latin American
            Studies Program and Emerging Markets Specialization, expects strong
            market reforms in Venezuela, telling Voice of America, “You have to
            get rid of that [government intervention] and just allow the market
            to reappear, which doesn&rsquo;t really take very long if the
            situation on the ground is stable.”{' '}
            <a href="http://jhu.informz.net/z/cjUucD9taT04MzgwNjY0JnA9MSZ1PTEwNzE4NDI1NTImbGk9NjM3Mjk4MTE/index.html">
              Read more
            </a>
            .
          </p>

          <p>
            Foreign Policy Institute Senior{' '}
            <Core.BoldText>Hafed Al-Ghwell</Core.BoldText> wrote in Arab News
            that “activity surrounding Venezuela is just another reminder of yet
            another cycle of U.S.-led regime change that will probably end in
            disaster—this time in Latin America, not the usual Middle East.”{' '}
            <a href="http://jhu.informz.net/z/cjUucD9taT04MzgwNjY0JnA9MSZ1PTEwNzE4NDI1NTImbGk9NjM3Mjk4MTI/index.html">
              Read more
            </a>
            .
          </p>

          <Core.Share id="leadership-crisis-in-venezuela" />
        </Core.ContentSection>

        <Core.HorizontalRule />

        <Core.Flag>Sphere of Influence</Core.Flag>

        <Core.ContentSection id="magic-in-the-classroom">
          <Core.PrimaryHeading>Magic in the Classroom</Core.PrimaryHeading>
          <p>
            <Core.LeadIn>No one</Core.LeadIn> can wave a magic wand to solve all
            problems. But John McLaughlin, Distinguished Professor in Residence
            in the Merrill Center for Strategic Studies, taps into the power of
            magic to help his students gain real-world insights on navigating
            Washington once they leave the school.
          </p>

          <p>
            McLaughlin, who joined Johns Hopkins SAIS after a 32-year career in
            the federal government, including the Central Intelligence Agency,
            brings some innovative approaches to his teaching: from using magic
            in his lectures, to starting a magic-themed speaker series, “Defense
            Against the Dark Arts.” Here he talks about the inspiration for his
            somewhat unconventional pedagogical approach.
          </p>
        </Core.ContentSection>

        <Core.FullWidthImage image={johnMcLaughlin} alt="John McLaughlin" />

        <Core.ContentSection
          marginalia={
            <Core.Blockquote>
              I’m not saying Washington is evil, but I am teaching students to
              understand its bureaucracies — and to learn from the people who
              have worked successfully there so that they can follow in their
              footsteps.
            </Core.Blockquote>
          }
        >
          <p>
            “I personally love magic as a hobby. I perform at a few shows here
            and there outside of teaching. But in the classroom, I do it ever so
            slightly to help keep things lively. For example, I’ll be in the
            middle of a lecture, drinking a bottle of water, and unexpectedly,
            I’ll flip the bottle upside down, keeping the water from cascading
            out. Now with my students’ captive attention, I’ll say something
            akin to ‘in the intelligence world, sometimes you have to defy the
            laws of physics.’ It opens up great dialogue. I want them to start
            thinking outside the box, just like I try to do while teaching this
            course
          </p>

          <p>
            “Outside of the regular classes I teach, I’ve also started a speaker
            series called ‘Defense Against the Dark Arts,’ It’s open to any
            students, alumni, and staff members, and we interview three,
            sometimes four speakers a semester. If I could rename it in layman’s
            terms, it’d be titled ‘How to be Effective in Washington.’ I
            interview speakers like Madeleine Albright (former Secretary of
            State), Michelle Flounoy (former Under Secretary of Defense), David
            Sanger (New York Times journalist), Steve Hadley (former National
            Security advisor to George W. Bush), Bob Mueller (former FBI
            Director), and many more.
          </p>

          <p>
            “My goal is to have students hear for themselves how these folks got
            to where they are. What challenges did they face? And what skills
            are they looking for if they were hiring? Just like in defending
            yourself from the dark arts, in Washington you will encounter all
            sorts of people. Your job is to succeed in an environment that’s
            always a challenging place. I’m not saying Washington is evil, but I
            am teaching students to understand its bureaucracies — and to learn
            from the people who have worked successfully there so that they can
            follow in their footsteps
          </p>

          <p>
            “This series was Dean Eliot Cohen’s idea — I just developed it. Many
            students today are familiar with magic and wizards due to popular
            culture, and I think there are a lot of lessons to take from them
            that can apply to life in general, but especially when working in
            Washington
          </p>

          <p>
            “For example, choosing your friends wisely. I tell my students all
            the time, yes you need to build your network and acquire mentors,
            but what qualities do you want in that network? When you need help,
            do you feel comfortable asking for it? In one wizard’s case, his
            friends in various books were very pivotal not only to his success,
            but the success of the greater good
          </p>

          <p>
            “Another example is that sometimes the most harmful action is
            inaction. I want my students to know, the longer they delay, the act
            of indecision actually becomes an action — with your options
            diminishing. Sometimes in the intelligence world, you have to
            predict the future, even if you’re not ready
          </p>

          <p>
            “Also, I want my students to get to know people — both these
            speakers, and other people they encounter throughout their careers.
            I want them to be careful of their first impressions.” Just like
            magic, people aren’t always what they seem. Give people time, they
            usually surprise you.
          </p>

          <p>
            “This series has successfully grown from attracting about 10
            students to 50 to 60 students today. When you have speakers like
            Wendy Sherman, who negotiated the nuclear agreement with Iran, I
            want my students to really take away: How does someone do that, and
            how do they succeed?”
          </p>
          <Core.Share id="magic-in-the-classroom" />
        </Core.ContentSection>

        <Core.HorizontalRule />

        <Core.MoreResources>
          To stay up to date on SAIS experts in the news, visit{' '}
          <a
            href="http://www.sais-jhu.edu/opt-in"
            target="_blank"
            rel="noopener noreferrer"
          >
            sais-jhu.edu/opt-in
          </a>{' '}
          and select the option for <strong>The Brief</strong>, which is
          produced monthly by the Office of Marketing, Communications, and
          Strategic Initiatives.
        </Core.MoreResources>
      </Core.ContentWrapper>
    </Layout>
  );
};

export const query = graphql`
  query($directory: String!) {
    halBrands: file(
      relativePath: { regex: $directory }
      name: { eq: "hal-brands" }
    ) {
      ...ImagePragraphWrapImage
    }
    felipeCampante: file(
      relativePath: { regex: $directory }
      name: { eq: "felipe-campante" }
    ) {
      ...ImagePragraphWrapImage
    }
    craigOsborne: file(
      relativePath: { regex: $directory }
      name: { eq: "craig-osborne" }
    ) {
      ...ImagePragraphWrapImage
    }
    christopherSands: file(
      relativePath: { regex: $directory }
      name: { eq: "christopher-sands" }
    ) {
      ...ImagePragraphWrapImage
    }
    jessicaFanzo: file(
      relativePath: { regex: $directory }
      name: { eq: "jessica-fanzo" }
    ) {
      ...ImagePragraphWrapImage
    }
    adriaLawrence: file(
      relativePath: { regex: $directory }
      name: { eq: "adria-lawrence" }
    ) {
      ...ImagePragraphWrapImage
    }
    jeremeyShiffman: file(
      relativePath: { regex: $directory }
      name: { eq: "jeremey-shiffman" }
    ) {
      ...ImagePragraphWrapImage
    }
    anneApplebaum: file(
      relativePath: { regex: $directory }
      name: { eq: "anne-applebaum" }
    ) {
      ...ImagePragraphWrapImage
    }
    samAsher: file(
      relativePath: { regex: $directory }
      name: { eq: "sam-asher" }
    ) {
      ...ImagePragraphWrapImage
    }
    danielMarston: file(
      relativePath: { regex: $directory }
      name: { eq: "daniel-marston" }
    ) {
      ...ImagePragraphWrapImage
    }
    paulaThornhill: file(
      relativePath: { regex: $directory }
      name: { eq: "paula-thornhill" }
    ) {
      ...ImagePragraphWrapImage
    }
    eugeneFinkel: file(
      relativePath: { regex: $directory }
      name: { eq: "eugene-finkel" }
    ) {
      ...ImagePragraphWrapImage
    }
    walterAndersen: file(
      relativePath: { regex: $directory }
      name: { eq: "walter-andersen" }
    ) {
      ...ImagePragraphWrapImage
    }
    lingChen: file(
      relativePath: { regex: $directory }
      name: { eq: "ling-chen" }
    ) {
      ...ImagePragraphWrapImage
    }
    sarahJordaan: file(
      relativePath: { regex: $directory }
      name: { eq: "sarah-jordaan" }
    ) {
      ...ImagePragraphWrapImage
    }
    sarahParkinson: file(
      relativePath: { regex: $directory }
      name: { eq: "sarah-parkinson" }
    ) {
      ...ImagePragraphWrapImage
    }
    nargesBajoghli: file(
      relativePath: { regex: $directory }
      name: { eq: "narges-bajoghli" }
    ) {
      ...ImagePragraphWrapImage
    }
    china: file(relativePath: { regex: $directory }, name: { eq: "china" }) {
      ...MarginaliaImage
    }
    americaFirst: file(
      relativePath: { regex: $directory }
      name: { eq: "america-first" }
    ) {
      ...MarginaliaImage
    }
    venezuela: file(
      relativePath: { regex: $directory }
      name: { eq: "venezuela" }
    ) {
      ...MarginaliaImage
    }
    johnMcLaughlin: file(
      relativePath: { regex: $directory }
      name: { eq: "john-mclaughlin" }
    ) {
      ...FullWidthImage
    }
  }
`;

export default DepartmentContent;
